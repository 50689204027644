@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: iranSans;
    src: url(./fonts/IRANSansX-Regular.ttf);
  }
  @font-face {
    font-family: iranSansBold;
    src: url(./fonts/IRANSansX-Bold.woff2);
  }
  @font-face {
    font-family: iranSansExtraBold;
    src: url(./fonts/IRANSansX-ExtraBold.woff2);
  }

  body {
    @apply overflow-x-hidden font-iranSans;
  }
  body::-webkit-scrollbar {
    /* Customize the scrollbar width */
    @apply w-2 gradiant__bg;
  }

  body::-webkit-scrollbar-track {
    /* Customize the scrollbar track */
    @apply m-5 bg-white rounded-lg;
  }

  body::-webkit-scrollbar-thumb {
    /* Customize the scrollbar thumb */
    @apply rounded-sm gradiant__bg;
  }
}
@layer components {
  .gradiant__bg {
    background: linear-gradient(120deg, #3a8ef6, #6f3afa);
  }
  .blue__gradiant__bg {
    background: linear-gradient(180deg, #2aa7ff, #0957de);
  }
  .blue__text__gradiant {
    /* background-image: radial-gradient(circle, red, blue); */
    background: linear-gradient(120deg, #2aa7ff, #0957de);
    -webkit-background-clip: text;
    color: transparent;
  }
  .btn {
    @apply px-4 py-2 text-sm text-white transition rounded-lg md:py-2 xl:py-2.5 lg:text-sm xl:text-base gradiant__bg;
  }
  .btn:hover {
    @apply opacity-60;
  }
  .our__service__item__img {
    @apply size-36 md:size-28 lg:size-36 xl:size-40;
  }
}
